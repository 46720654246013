<template>
  <div class="index-container">
    <div class="bread"> <span @click="home()">操作台 / </span><span class="gray">准备出租</span></div>
      <el-row class="table-body">
        <div class="line clearfix">
          <h5 class="fl title">准备出租车辆</h5>
          <div class="fr search">
            <el-input size="small" type="text" placeholder="请输入车牌号" v-model="keywords">
              <template slot="prefix">
                <el-button icon="el-icon-search" type="text" disabled></el-button>
              </template>

            </el-input>
            <el-button type="primary" size="small" @click="getList">查询</el-button>
          </div>
        </div>
        <div class="list">
          <el-table
              :data="tableData"
              style="width: 100%">
            <el-table-column
                fixed
                type="index"
                label="序号"
                width="80">
            </el-table-column>
            <el-table-column
                prop="carNum"
                label="车牌号"
                width="300">
            </el-table-column>
            <el-table-column
                prop="carBrand"
                label="品牌型号"
                min-width="120">
              <template slot-scope="scope">
                {{scope.row.carBrand}}{{scope.row.carMode}}
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                min-width="120">
              <template slot-scope="scope">
                <el-button
                    @click="toShelves(scope.row)"
                    type="text"
                    size="small">
                  上架车辆
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pages">
          <el-pagination
              background
              layout="prev, pager, next"
              @current-change="handleCurrentChange"
              :hide-on-single-page="showPage"
              :page-size="pageSize"
              :total="total">
          </el-pagination>
        </div>
      </el-row>
    <edit-shelves ref="shelves" v-on:getList="getList" ></edit-shelves>
  </div>
</template>

<script>
import EditShelves from "@/components/EditShelves";
import { getList } from "@/utils/api/car";
export default {
  name: "zbcz",
  components:{
    EditShelves
  },
  data(){
    return{
      total:11, //总条数
      pageNo:1,
      pageSize:10,
      showPage:true,
      tableData: [], //表格数据
      keywords:"",//搜索关键字
      editOne:'', //编辑数据
    }
  },
  created() {
    this.getList()
  },
  methods:{
    home(){
      this.goHome()
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getList()
    },
    toShelves(row){
      this.$refs['shelves'].showEdit(row)
    },
    getList(){
      let data={
        type :"1",
        rows: this.pageSize,
        // totalPage:this.pageNo,
        page: this.pageNo,
      }
      if (this.keywords !== ""){
        data.carNum=this.keywords
      }
      getList(data).then(res=>{
        if(res.code === 0){
          this.tableData = res.data.list
          this.total = Number(res.data.total)
        }
      })
    }
  }
}
</script>

<style  lang="less">
.table-body{
  background-color: #FFFFFF;
  padding:30px ;
  padding-top: 0;
  .line{
    height: 90px;
    line-height: 90px;
    .title{
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }
    .search{
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: flex-end;

      .el-input{
        font-size: 12px;
        margin-right: 12px;

        .el-input__inner{
          border: none;
          background-color: #FAFAFA;
          padding-left: 50px;
        }
        .el-input__prefix{
          width: 40px;
          &:after{
            content: '';
            width: 1px;
            height: 15px;
            background-color: #D8D7D7;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }
  }
}
</style>
